import React, { useState, useEffect } from 'react'
import "./assets/css/App.css";
import { TopBar } from "./components/TopBar";
import Footer from "./components/Footer";
import Form from "./components/Form";
import FormSancoSeccion from "./components/FormSancorSeccion"
import { ImgPanel } from "./components/ImgPanel";
import { BottomImgPanel } from "./components/BottomImgPanel";
import PrivacyPoliciesSancor from './components/PrivacyPoliciesSancor';

function App() {

  // Data configurable desde el landing-config.json
  const [data, setData] = useState(null);

  // Metodo para obtener la data de landing-config.json
  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }

  const url = window.location.pathname;

  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {data !== null ? (
        <div className="fullPage">

          <div className="top-bar-position">
            <TopBar data={data}/>
          </div>
          {url === "/sancor-seguros" ? (
            <div>
              <div className="img-panel-position">
                <ImgPanel/>
              </div>
              <div className='choose-us-position'>
                <FormSancoSeccion/>
              </div>
            </div>
          ):url === "/legales" ? (
            <>
              <PrivacyPoliciesSancor/>
            </>
          ):(
            <div>
              <div className="form-position">
                <Form/>
              </div>

              <div className="img-panel-position">
                <ImgPanel/>
              </div>

              <div className="bottom-img-panel-position">
                <BottomImgPanel/>
              </div>
            </div>
        )}
          <div className="footer-position">
            <Footer data={data}/>
          </div>
        </div>

      ):(<></>)}
    </div>
  )
}

export default App