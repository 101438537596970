import React from "react";
import logo from "../assets/img/logo.png";
import Floating from "./Floating.js";
import "../assets/css/topBar.css";

export const TopBar = (data) => {

  const url = window.location.pathname;

  return (
    <div className={url === "/" ? "top-bar" : "top-bar-sancor"}>
      <div className="img-container-top-bar">
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>
      {url === "/" ? (
        <div className="call-top-bar">
          <Floating data={data.data}/>
        </div>
      ):(<span></span>)}
    </div>
  );
};
