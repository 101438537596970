import React from 'react'
import "../assets/css/privacyPolicies.css";

const PrivacyPoliciesSancor = () => {
  return (
    <div className="fullpage-topbar">
        <div className='container-topbar'>
            <br/>
            <h1 className='policies-title'>
            Políticas de privacidad
            </h1>
            <br/>
            <p>
            La presente acción promocional es extendida por PROSEGUR ACTIVA ARGENTINA S.A., CUIT N° 30-70977656-4, Domicilio Legal: Tres Arroyos 2835, Ciudad Autónoma de Buenos Aires. Contacto: alarmas@prosegur.com en adelante “Prosegur”. La Política de Privacidad de PROSEGUR ACTIVA ARGENTINA S.A. puede consultarse en https://www.prosegur.com.ar/politica-privacidad.
            </p>
            <p>
            Promoción válida desde el 10 de octubre de 2024 al 31 de octubre de 2025 inclusive para clientes de Banco del Sol S.A. y de Sancor Cooperativa de Seguros Limitada que contraten el servicio de Alarmas Monitoreadas de Prosegur exclusivamente para vivienda -consumidor final- y para comercios para propiedades ubicadas en las siguientes ciudades: C.A.B.A, Gran Buenos Aires, Ciudades de: La Plata, Santa Fe, Rosario, Resistencia, Corrientes, Mendoza, Neuquén, Bahía Blanca, San Nicolás, Mar del Plata, Córdoba y Tucumán.
            </p>
            <p>
            La promoción puede ser rescindida en forma anticipada al plazo estipulado por decisión de las partes.
            </p>
            <p>
            Promoción Especial: Con la contratación de cualquier sistema de alarma monitoreada, los clientes de Banco del Sol S.A. o Sancor Cooperativa de Seguros Limitada acceden a una bonificación del 100% en la instalación de una cámara IP interior o exterior. Válido hasta agotar stock de 500 unidades. También se bonificará el abono mensual del monitoreo de la cámara en un 100% durante un mes. Adicionalmente, si el cliente asocia al medio de débito del contrato una tarjeta emitida por el Banco del Sol, tendrá un mes más adicional sin costo siendo en total 2 meses de abono bonificado al 100% para este caso. No acumulable con otros beneficios que ofrecen aliados comerciales de Prosegur.
            </p>
            Para que se otorgue dicha promoción, el cliente deberá necesariamente acceder al link adjunto en esta comunicación y dejar sus datos para que un comercial de Prosegur se ponga en contacto.
            <p>
            El equipo es otorgado en comodato. Sujeto a que el cliente supere el proceso de aceptación exigido por Prosegur para su cartera en general y a la aceptación de las condiciones particulares y generales del servicio.
            </p>
            <p>
            Promoción no aplicable a: equipos de detección de incendios, anexo de equipos de back up celular o GPRS, servicio de cajas fuertes monitoreadas.
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
  )
}

export default PrivacyPoliciesSancor