import React from "react";

import promocionAlarma1 from "../assets/img/familiaImage.png";
//import promocionAlarma1Mobile from "../assets/img/promoMobile.jpg";
import promocionAlarma1Mobile from "../assets/img/familiaImageMobile.png";

import sancordesk from "../assets/img/sancor_desk_1.png"
import sancormobile from "../assets/img/sancor_mobile_1.png"

import "../assets/css/imgPanel.css";

export const ImgPanel = (img, img_mobile) => {
  //const text = "Recibe asesoramiento sin cargo"; //??
  const text = "Prosegur Alarmas"; //??

  const url = window.location.pathname;

  return (
    <div className="panel-title">
      {url !== "/sancor-seguros" ? (
        <div className="img-panel">
          <div className="img-panel-overlay"/>
                <img
                  src={promocionAlarma1Mobile}
                  alt={text}
                  className="img-mobile"
                />
                <img
                  src={promocionAlarma1}
                  alt={text}
                  className="img-desk"
                />
        </div>
      ):(
        <div className="img-panel">
          {window.innerWidth <= 1001 ? (
            <img
              src={sancormobile}
              alt={text}
              className="img-mobile"
            />
          ):(
            <img
            src={sancordesk}
            alt={text}
            className="img-desk"
          />
          )}
      </div>
      )}
    </div>
  );
};
