import React from 'react'
import "../assets/css/FormSancorSeccion.css";
import imgFormMob from "../assets/img/sancor-form-mobile.png";
import imgFormDesk from "../assets/img/sancor-form-desk.png";
import FormSancor from './FormSancor';

const FormSancoSeccion = () => {
  return (
    <div className='why-choose-us'>
        <div className='why-choose-us-container'>
            <div className='why-choose-us-img-position'>
                {window.innerWidth <= 1001 ? (
                    <img
                        src={imgFormMob}
                        alt='smart-security'
                    />
                ):(
                    <img
                        src={imgFormDesk}
                        alt='smart-security'
                    />
                )}
            </div>
            <div className='why-choose-us-content'>
                <h1 className='why-choose-us-title'>
                    ¡PORQUE VALORAMOS TU CONFIANZA, TE DAMOS MÁS SEGURIDAD!
                </h1>
                <FormSancor/>
            </div>
        </div>
    </div>
  )
}

export default FormSancoSeccion