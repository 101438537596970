import React from 'react'
import "../assets/css/footer.css";
import logo from "../assets/img/logo_footer.png";
import PhoneFooter from "./PhoneFooter.js";


const Footer = (data) => {

  const url = window.location.pathname;

  return (
    <div className='footer'>
      <div className={url !== "/sancor-seguros" ? "img-container-footer":"img-container-footer-sancor"}>
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div className="call-footer">
      {url === "/sancor-seguros" ? (
        <a href="/legales" className='text-legals'>
          Políticas de privacidad
        </a>
      ):url === "/legales" ? (
        <span></span>
      ):(
        <PhoneFooter data={data.data}/>
      )}
      </div>

    </div>
  )
}

export default Footer
